import React from 'react';
import GalleryImage1 from '../images/washbros-gallery-1.jpeg';
import GalleryImage2 from '../images/washbros-gallery-2.jpeg';
import GalleryImage3 from '../images/washbros-gallery-3.jpeg';
import GalleryImage4 from '../images/washbros-gallery-4.jpeg';
import GalleryImage5 from '../images/washbros-gallery-5.jpeg';
import GalleryImage6 from '../images/washbros-gallery-6.jpeg';

const ServicesList = () => {
    return (
        <section id="services">
            <h2>Self Service</h2>

            <div className="services-list">
                <div className="service">
                    <h3>Free Dry</h3>
                    <p>For wash customers</p>
                </div>
                <div className="service">
                    <h3>Brand New Equipment</h3>
                    <p>Over 30 brand new washers and dryers</p>
                </div>
                <div className="service">
                    <h3>Free Wifi</h3>
                    <p>High-speed internet while you wait</p>
                </div>
                <div className="service">
                    <h3>Open 24 Hours</h3>
                    <p>Come at your convenience</p>
                </div>
                <div className="service">
                    <h3>Drinking Water Machine</h3>
                    <p>Refill with crystal-clear drinking water</p>
                </div>
            </div>

            {/* <div className="specials-list">
                <h2>Nighttime Specials</h2>

                <div className="services-list">
                    <div className="service">
                        <h3>Free 5 Gallon Water Tuedays!</h3>
                        <p>Every Tuesday night from 9pm - 2am</p>
                    </div>
                    <div className="service">
                        <h3>Free Soap Wednesdays!</h3>
                        <p>Every Wednesday night from 9pm - 2am</p>
                    </div>
                    <div className="service">
                        <h3>Free Coffee Thursdays!</h3>
                        <p>Every Thursday night from 9pm - 2am</p>
                    </div>
                </div>
            </div> */}

            <div className="gallery-list">
                <div>
                    <img src={GalleryImage1} alt="Gallery 1" />
                </div>
                <div>
                    <img src={GalleryImage2} alt="Gallery 2" />
                </div>
                <div>
                    <img src={GalleryImage3} alt="Gallery 3" />
                </div>
                <div>
                    <img src={GalleryImage4} alt="Gallery 4" />
                </div>
                <div>
                    <img src={GalleryImage5} alt="Gallery 5" />
                </div>
                <div>
                    <img src={GalleryImage6} alt="Gallery 6" />
                </div>
            </div>
        </section>
    );
};

export default ServicesList;
