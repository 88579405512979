import Header from './components/Header';
import IntroductionBanner from './components/IntroductionBanner';
// import Flyers from './components/Flyers';
import ServicesList from './components/ServicesList';
import Pricing from './components/Pricing';
import Location from './components/Location';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <>
      <Header />
      <div className="App">
        <IntroductionBanner />
        {/* <Flyers /> */}
        <Location />
        <ServicesList />
        <Pricing />
        <Footer />
      </div>
    </>
  );
}

export default App;
